import React, {useContext, useEffect, useState} from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { addDoc, collection } from 'firebase/firestore';
import { FirebaseContext } from '../firebase/FirebaseContext';
import { convertDateTime, sleep } from '../utils';
import moment from 'moment';

export const VenueAvailabilityDialog: React.FC<any> = ({
  openModal,
  handleCancel,
  handleContinue,
  venueSettingsData,
  guestForm
}) => {
  const { db, functions} = useContext(FirebaseContext);
  const [seasonalClosure, setSeasonalClosure] = useState<boolean>(false);
  const [venueAvailable, setVenueAvailable] = useState<boolean>(false);

  useEffect(() => {
    const runCheck = async () => {
      const checkVenueIsAvailable = httpsCallable(functions, 'availability-checkVenueIsAvailable');
      const hasAvailability = await checkVenueIsAvailable(guestForm);
      const val = Boolean(hasAvailability.data || false)
      setVenueAvailable(val);
      await sleep(250);
      if (!val) {
        handleContinue(false)
      }
    }
    const storeSeasonalClosureRequest = async () => {
      const bookingRequest = {
          ...guestForm,
          checkin: moment(convertDateTime(guestForm.checkin)).toDate(),
          checkout: moment(convertDateTime(guestForm.checkout)).toDate(),
          season_start_date: guestForm.season_start_date ? moment(convertDateTime(guestForm.season_start_date)).toDate() : null,
          season_end_date: guestForm.season_end_date ? moment(convertDateTime(guestForm.season_end_date)).toDate() : null,
          venue_doc_id: guestForm.venueUid,
          waitlist_doc_id: 'GUEST FORM',
      }
      const colRef = collection(db, 'guest_form_out_of_season');
      await addDoc(colRef, bookingRequest);
    }
    if (guestForm && guestForm.venueUid && guestForm.season_start_date && guestForm.season_end_date) {
      const seasonStart = moment(convertDateTime(guestForm.season_start_date, 'YYYY-MM-DD'));
      const seasonEnd = moment(convertDateTime(guestForm.season_end_date, 'YYYY-MM-DD'));
      setSeasonalClosure(false);
      if (!moment(guestForm.checkin).isBetween(seasonStart, seasonEnd) ||
      !moment(guestForm.checkout).isBetween(seasonStart, seasonEnd)) {
        setSeasonalClosure(true);
        storeSeasonalClosureRequest();
        return;
      }
    }
      if ( venueSettingsData && venueSettingsData.guest_form_availability_check && guestForm && guestForm.venueUid) {
        setVenueAvailable(false);
        runCheck()
      } else {
        handleContinue(false);
      }
  }, [db, functions, handleContinue, guestForm, venueSettingsData]);

  return (
    <Dialog open={openModal && (venueAvailable || seasonalClosure)}>
      <DialogTitle style={{minWidth: 300}}>
          {seasonalClosure ? 'Closed for the season' : venueAvailable ? 'Rooms currently available' : 'Submitting request'}
      </DialogTitle>
      <DialogContent>
          {seasonalClosure ? `We apologize for the inconvenience, there is no waitlist for your chosen dates as the hotel is open only from ${convertDateTime(guestForm.season_start_date, 'MM/DD/YYYY')} to ${convertDateTime(guestForm.season_end_date, 'MM/DD/YYYY')}. If you would like to stay at the hotel, please book during this period. Thank you.` 
          : venueAvailable ? 'There are rooms available for your waitlist selected criteria. Please go back to the booking page and try booking directly from there. If you are still encountering issues, please call the reservation desk at the hotel.' 
          : `Please wait...`}
          <Box sx={{ width: '100%'}}>
            {seasonalClosure || venueAvailable ? <></> : <LinearProgress /> }
          </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={!venueAvailable && !seasonalClosure} onClick={() => handleCancel()} variant="contained">Go Back</Button>
        {/* <Button disabled={!venueAvailable} style={{minWidth: 102}} onClick={() => handleContinue(true)}>JOIN WAITLIST</Button> */}
      </DialogActions>
    </Dialog>
  )
}