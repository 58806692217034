import React, { useEffect, useMemo, useState } from 'react';

import TextField from '@mui/material/TextField';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { NumberInput } from '../NumberInput';
import { Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import moment from 'moment';

export const Name: React.FC<any> = ({
  defaultValue
}) => {
  return (
    <TextField
      defaultValue={defaultValue || ''}
      margin="dense"
      id="name"
      name="name"
      label="Name"
      autoFocus
      required
      fullWidth
    />
  );
};

export const RezylistTextField: React.FC<any> = ({
  id,
  label,
  type='text',
  required = true,
  autoFocus = false,
  disabled = false,
  defaultValue,
  isError = false,
  ...props
}) => {
  return (
    <TextField
      defaultValue={defaultValue || undefined}
      margin="dense"
      id={id}
      name={id}
      label={label}
      autoFocus={autoFocus}
      type={type}
      disabled={disabled}
      required={required}
      fullWidth
      error={isError}
      color={isError ? 'error' : 'primary'}
      {...props}
    />
  );
};

export const NotificationPreference: React.FC<any> = ({
  id, 
  defaultValue
}) => {
  return (
    <RadioGroup 
      id={'notificationPreference'} 
      name={'notificationPreference'} 
      defaultValue={defaultValue || 'both'} 
      row
    >
      <FormControlLabel key={'phone'} value={'phone'} label={'Phone'} control={<Radio />} />
      <FormControlLabel key={'email'} value={'email'} label={'Email'} control={<Radio />} />
      <FormControlLabel key={'both'} value={'both'} label={'Both'} control={<Radio />} />      
    </RadioGroup>
  )
}
export const Phone: React.FC<any> = ({
  defaultValue
}) => {
  return (
    <TextField
      defaultValue={defaultValue || undefined}
      margin="dense"
      id="phone"
      name="phone"
      label="Phone"
      type="tel"
      required
      fullWidth
    />
  );
};

export const Email: React.FC<any> = ({
  defaultValue,
  autoFocus = false,
}) => {
  return (
    <TextField
      defaultValue={defaultValue || undefined}
      margin="dense"
      id="email"
      name="email"
      label="Email"
      type="email"
      required
      fullWidth
      autoFocus={autoFocus}
    />
  );
};

export const CheckInCheckOut: React.FC<{
  isError?: boolean | null;
  checkin: Date | null;
  checkout: Date | null;
  setCheckin: (date: Date) => void;
  setCheckout: (date: Date) => void;
  labelStart?: string;
  labelEnd?: string;
  isOptional?: boolean;
}> = ({ isError, checkin, setCheckin, checkout, setCheckout, labelStart, labelEnd, isOptional = false }) => {

  useEffect(() => {
    setDatesError(isError || false);
  }, [isError])
  
  const [datesError, setDatesError] = useState<boolean>(isError || false);
  
  const minCheckout = useMemo(() => {
    const newCheckout = checkin ? moment(checkin).add(1, 'days') : moment().add(1, 'days');
    return newCheckout.toDate();
  }, [checkin]);

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          label={labelStart || "Check In"}
          value={checkin}
          minDate={isOptional ? undefined : new Date()}
          showToolbar={false}
          onChange={(newValue: Date | null) => {
            setDatesError(false);
            if (newValue) {
              setCheckin(newValue);
            }
          }}
          onAccept={(newValue: Date | null) => {
            setDatesError(false);
            if (newValue) {
              setCheckin(newValue);
              if (!checkout) {
                setCheckout(moment(newValue).add(1, 'days').toDate())
              }
            }
          }}
          renderInput={(params: any) => (
            <TextField
              style={{ flex: 1 }}
              margin="dense"
              required={!isOptional}
              focused={datesError || false}
              color={datesError && !isOptional ? 'error' : 'primary'}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          label={labelEnd || "Check Out"}
          value={checkout}
          
          minDate={isOptional ? undefined : minCheckout}
          showToolbar={false}
          onChange={(newValue: Date | null) => {
            setDatesError(false);
            if (newValue) {
              setCheckout(newValue);
            }
          }}
          onAccept={(newValue: Date | null) => {
            setDatesError(false);
            if (newValue) {
              setCheckout(newValue);
            }
          }}
          renderInput={(params: any) => (
            <TextField
              style={{ flex: 1, marginLeft: 4 }}
              margin="dense"
              // required={!isOptional}
              // focused={datesError || false}
              // color={datesError && !isOptional ? 'error' : 'primary'}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

export const Adults: React.FC<{
  adults: number;
  setAdults: (adults: number) => void;
}> = ({ adults, setAdults }) => {
  return (
    <NumberInput
      value={adults}
      name="Adults"
      min={1}
      max={8}
      onChange={(v: number) => setAdults(v)}
    />
  );
};

export const Children: React.FC<{
  children: number;
  setChildren: (children: number) => void;
}> = ({ children, setChildren }) => {
  return (
    <NumberInput
      value={children}
      name="Children"
      min={0}
      max={5}
      onChange={(v: number) => setChildren(v)}
    />
  );
};

export const Rooms: React.FC<{
  rooms: number;
  setRooms: (children: number) => void;
}> = ({ rooms, setRooms }) => {
  return (
    <NumberInput
      value={rooms}
      name="Rooms"
      min={1}
      max={4}
      onChange={(v: number) => setRooms(v)}
    />
  );
};

export const VIPGuest: React.FC<{
  vip: boolean;
}> = ({ vip}) => {
  return (
    <FormControlLabel
      control={<Checkbox id="vip_guest" name='vip_guest' color="primary" defaultChecked={Boolean(vip)} />}
      label="VIP Guest"
    />
  )
}

export const Staff = () => {
  return (
    <TextField
      margin="dense"
      id="staff"
      name="staff"
      label="Staff"
      type="text"
      fullWidth
    />
  );
};

export const Notes: React.FC<any> = ({
  defaultValue
}) => {
  return (
    <TextField
      defaultValue={defaultValue || ''}
      margin="dense"
      id="notes"
      name="notes"
      label="Hotel Notes"
      type="text"
      minRows="3"
      multiline
      fullWidth
    />
  );
};

export const SpecialRequests: React.FC<any> = ({
  defaultValue
}) => {
  return (
    <TextField
      defaultValue={defaultValue || ''}
      margin="dense"
      disabled={true}
      id="special_requests"
      name="special_requests"
      label="Guest Special Requests"
      type="text"
      minRows="3"
      multiline
      fullWidth
    />
  );
};


export const GuestRequests: React.FC<{
  guestRequest: string;
  setGuestRequest: (req: string) => void;
}> = ({ guestRequest, setGuestRequest }) => {
  return (
    <TextField
      value={guestRequest || ''}
      name="Special Requests"
      label="Special Requests"
      type="text"
      margin='dense'
      minRows="3"
      multiline
      fullWidth
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setGuestRequest(event.target.value);
      }}
    />
  );
};