import React, { useCallback, useContext } from 'react';
import { doc, updateDoc } from 'firebase/firestore';

import { AppBar, Box, Toolbar } from '@mui/material';
import { FirebaseContext } from '../../firebase/FirebaseContext';
import { showInfoToast, showErrorToast, getError, isValidAction, actionTypes } from "../../utils";

import MobileActionsBar from './MobileActionsBar';
import DesktopActionsBar from './DesktopActionsBar';
import { useMobile } from '../../utils/useMobile';

type ActionsBarProps = {
  venueList: any;
  venueUid: any;
  records: any;
  selected: any;
  setSelected: any;
  handleAddGuest: any;
  handleShowHistorical: any;
  handleFilterBooked: any;
}
export const ActionsBar: React.FC<any> = (props: ActionsBarProps) => {
  const { user, db, currentManager } = useContext(FirebaseContext);
  const { isMobile } = useMobile();
  const { selected, setSelected } = props;
  const handleRemoveSelected = useCallback(async () => {
    try {
      if (!user || !currentManager) {
        return;
      }

      let blnMissingPermissons = false;
      selected.forEach(async (item: any) => {
        if (isValidAction(actionTypes.DELETE_WAITLIST_REQUEST, currentManager, item.venue_uid)) {
            const docRef = doc(db, 'venues', item.venue_uid, 'waitlist', item.doc_id);
            await updateDoc(docRef, { 
              is_archived: true,
              archive_date: new Date(),
              update_by: user?.email || user?.uid || 'Manager',
              last_update_by: currentManager ? currentManager.first_name + ' ' + currentManager.last_name : user?.email || 'Manager'
            });
        } else {
          blnMissingPermissons = true;
        }
      })

      setSelected([]);
      if (blnMissingPermissons) {
        showInfoToast('Archived requests.  Some requests were not removed due to missing permissions.')  
      } else {
        showInfoToast('Successfully archived requests.');
      }
    } catch (e) {
      showErrorToast(getError(e).message)
    }
  }, [user, db, setSelected, selected, currentManager]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar variant="dense">
          {
            isMobile ?
              <MobileActionsBar {...props} currentManager={currentManager} handleRemoveSelected={handleRemoveSelected} /> :
              <DesktopActionsBar {...props} currentManager={currentManager} handleRemoveSelected={handleRemoveSelected} />
          }
        </Toolbar>
      </AppBar>
    </Box>
  );
};
